import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as SpinnerRS } from 'reactstrap';
import styled from 'styled-components';
import './Spinner.scss';

const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: white;
  opacity: 0.5;
`;

const Spinner = ({ size = 50, rootContainerClassName = '' }) => (
  <div className={`spinnerRootContainer ${rootContainerClassName}`}>
    <Container className="spinnerContainer"></Container>
    <SpinnerRS
      style={{
        width: size,
        height: size,
        position: 'absolute',
        top: '60%',
        left: '50%',
        marginTop: -(size / 2),
        marginLeft: -size / 2,
        zIndex: 2000,
      }}
      color="primary"
    />
  </div>
);

Spinner.propTypes = {
  size: PropTypes.number,
  rootContainerClassName: PropTypes.string,
};

export default Spinner;
