import React from 'react';
import ReactDOM from 'react-dom';
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react';
import ViewportProvider from 'providers/Viewport';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'sanitize.css/sanitize.css';
import './theme/icomoon/style.css';
import './theme/custom-bootstrap.scss';

const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_SECRET_KEY,
  refreshInterval: process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL,
  appName: process.env.REACT_APP_UNLEASH_APP_NAME,
};

const unleashClient = new UnleashClient(unleashConfig);

const startClient =
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_UNLEASH_START_CLIENT === 'true';

ReactDOM.render(
  <React.StrictMode>
    <ViewportProvider>
      <FlagProvider unleashClient={unleashClient} startClient={startClient}>
        <App />
      </FlagProvider>
    </ViewportProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
