export const routes = {
  ROOT: '/',
  LOGIN: '/login',
  MULTI_FACTOR: '/2fa',
  MULTI_FACTOR_SETUP: '/activate-2fa',
  REGISTRATION: '/registration',
  RESET_PASSWORD: '/reset-password',
  EDIT_PROFILE: '/edit-profile',
  FORGOT_CREDENTIALS: '/forgot',
  LIBRARY: '/library',
  ADVANCED_SEARCH: '/advanced-search',
  DISTRIBUTORS: '/distributors',
  FILM: '/film',
  FILM_DETAILS: '/film-details',
  // ADD_FILM: '/add-film',
  PRIVACY_POLICY: '/privacy',
  CONTACT_US: '/contact',
  SEARCH_RESULTS: '/search-results',
  DISTRIBUTOR_FILMS: '/distributor-films',
  DATA_AUDIT: '/data-audit',
  FILMS_BY_METRIC: '/films-by-metric',
  PREDICTIONS: '/predictions',
  PERSON: '/person',
  ANALYTICS_REPORT: '/analytics-report',
  REPORT_PRINT: '/report-print',
};
