import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useViewport from 'hooks/useViewport';
import { routes } from 'routes/routes';
import { DropdownItem, Collapse, ButtonGroup } from 'reactstrap';
import constants from 'utils/constants';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

const SingleNotification = ({
  notificationType,
  summary,
  timeDifference,
  details,
  dismissNotification,
}) => {
  const location = useLocation();

  const [notificationDetailsOpen, setNotificationDetailsOpen] = useState(false);

  const toggleNotificationDetails = () =>
    setNotificationDetailsOpen((prevState) => !prevState);

  const getNotificationClassNameByType = (type) =>
    type === constants.notificationTypes[0]
      ? 'icon-info'
      : 'icon-alert-triangle';

  const [notificationClassName, setNotificationClassName] = useState(
    getNotificationClassNameByType(notificationType),
  );

  const handleRedirectToNotificationDetailsPage = (event) => {
    if (location.pathname === routes.DATA_AUDIT) {
      event.preventDefault();

      if (
        location.hash &&
        location.hash === `#${constants.filmDataQualityCardId}`
      ) {
        scroller.scrollTo(constants.filmDataQualityCardId, {
          duration: 500,
        });
      }
    }
  };

  const handleNotificationDismiss = () => {
    toggleNotificationDetails();
    dismissNotification();
  };

  return (
    <div className="singleNotificationContainer">
      <DropdownItem
        className={notificationClassName}
        onClick={toggleNotificationDetails}
        toggle={false}
        active={notificationDetailsOpen}
      >
        <div className="notificationSummaryContainer">
          <div className="notificationSummaryTitleContainer">{summary}</div>
          <small className="text-secondary">{timeDifference}</small>
        </div>
      </DropdownItem>
      <Collapse
        isOpen={notificationDetailsOpen}
        className="notificationDetailsContainer"
      >
        {details}
        <ButtonGroup>
          <a
            href={`${routes.DATA_AUDIT}#data-quality`}
            onClick={(event) => handleRedirectToNotificationDetailsPage(event)}
          >
            Details
          </a>
          <button
            type="button"
            className="notification-dismiss-btn"
            onClick={handleNotificationDismiss}
          >
            Dismiss
          </button>
        </ButtonGroup>
      </Collapse>
    </div>
  );
};

SingleNotification.propTypes = {
  notificationType: PropTypes.string,
  summary: PropTypes.string,
  timeDifference: PropTypes.string,
  details: PropTypes.string,
  dismissNotification: PropTypes.func,
};

export default SingleNotification;
