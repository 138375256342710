import React from 'react';
import PropTypes from 'prop-types';
import { PublicFooter } from 'components/navigation/PublicFooter';
import { PublicWrapper } from './elements';

export const PublicLayout = ({ children }) => (
  <PublicWrapper>
    {children}
    <PublicFooter />
  </PublicWrapper>
);

PublicLayout.propTypes = {
  children: PropTypes.any,
};
