import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { breakPointValues } from 'theme/breakpoints';

const checkViewportDevice = (identifier, windowWidth) =>
  windowWidth <= breakPointValues[identifier];

const getWindowDimensions = () => {
  const windowWidth =
    typeof window !== 'undefined' ? document.documentElement.clientWidth : 1200;
  const windowHeight =
    typeof window !== 'undefined' ? document.documentElement.clientHeight : 800;

  return {
    windowWidth,
    windowHeight,
  };
};

const deviceWidths = (width) => ({
  isTiny: checkViewportDevice('tiny', width),
  isSmall: checkViewportDevice('small', width),
  isIpad: checkViewportDevice('ipad', width),
  isMedium: checkViewportDevice('medium', width),
  isMediumLaptop: checkViewportDevice('mediumLaptop', width),
  isSmallLaptop: checkViewportDevice('smallLaptop', width),
});

const fetchDevice = (windowWidth) => {
  if (windowWidth < breakPointValues.tiny) {
    return 'tiny';
  }
  if (windowWidth <= breakPointValues.small) {
    return 'small';
  }
  if (
    windowWidth > breakPointValues.small &&
    windowWidth < breakPointValues.medium
  ) {
    return 'medium';
  }
  if (
    windowWidth > breakPointValues.medium &&
    windowWidth < breakPointValues.mediumLaptop
  ) {
    return 'large';
  }

  return 'xlarge';
};

const { windowHeight, windowWidth } = getWindowDimensions();

const initialState = {
  width: windowWidth,
  height: windowHeight,
  device: fetchDevice(windowWidth),
  ...deviceWidths(windowWidth),
  headerHeight: 67,
  setHeaderHeight: () => {},
  footerHeight: 0,
  setFooterHeight: () => {},
};

export const ViewportContext = createContext(initialState);

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(windowWidth);
  const [height, setHeight] = useState(windowHeight);

  const [devices, setDevices] = useState(deviceWidths(windowWidth));
  const [device, setDevice] = useState(fetchDevice(windowWidth));

  const [headerHeight, setHeaderHeight] = useState(67);
  const [footerHeight, setFooterHeight] = useState(0);

  const viewport = {
    width,
    height,
    ...devices,
    device,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  };

  const handleWindowResize = () => {
    const {
      windowHeight: windowHeightResize,
      windowWidth: windowWidthResize,
    } = getWindowDimensions();

    const deviceItem = fetchDevice(windowWidthResize);
    setWidth(windowWidthResize);
    setHeight(windowHeightResize);
    setDevices(deviceWidths(windowWidthResize));
    setDevice(deviceItem);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  );
};

ViewportProvider.propTypes = { children: PropTypes.any };

export default ViewportProvider;
