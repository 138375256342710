export const arrayToObject = (array = [], field) => {
  if (!field) return undefined;

  const finalObject = {};

  const uniqueFields = array
    .map((item) => item[field])
    .filter((value, index, self) => self.indexOf(value) === index);

  uniqueFields.forEach((uniqueField) => {
    finalObject[uniqueField] = array.filter(
      (item) => item[field] === uniqueField,
    );
  });
  return finalObject;
};

export const objectToArray = (object = {}) => {
  let finalArray = [];

  Object.keys(object).forEach((item) => {
    finalArray = [...finalArray, ...object[item]];
  });

  return finalArray;
};

export const generateYears = (min = 0, max = 0) => {
  const array = [];
  let current = max;
  while (current >= min) {
    array.push(current);
    current -= 1;
  }
  return array;
};
