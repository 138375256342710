import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { routes } from 'routes/routes';
import { isUserAuthenticated } from 'services/localstorage';
import { PrivatePrintLayout } from 'components/private-layout/PrivatePrintLayout';

export const PrivatePrintRoute = ({ exact, path, component: Component }) => {
  if (isUserAuthenticated()) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(routeProps) => (
          <PrivatePrintLayout>
            <Component {...routeProps} />
          </PrivatePrintLayout>
        )}
      />
    );
  }
  return <Redirect to={routes.LOGIN} />;
};

PrivatePrintRoute.propTypes = {
  exact: PropTypes.any,
  path: PropTypes.any,
  component: PropTypes.any,
};
