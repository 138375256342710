import React from 'react';
import { Input, Label, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { genres } from './constants';
import './Genres.scss';

const getGenresColumns = (allGenres, genresColumnLength) => {
  const genresColumns = Array.from(
    { length: Math.ceil(allGenres.length / genresColumnLength) },
    (value, index) =>
      allGenres.slice(
        index * genresColumnLength,
        index * genresColumnLength + genresColumnLength,
      ),
  );

  return genresColumns;
};

const GenresColumn = ({
  genresColumn,
  checkedGenres,
  setCheckedGenres,
  genresColumnClassName,
}) => (
  <Col className={genresColumnClassName}>
    {genresColumn.map((genre) => (
      <div className="form-check p-0" key={genre}>
        <Label check>
          <Input
            type="checkbox"
            checked={!!checkedGenres[genre]}
            onChange={(event) =>
              setCheckedGenres((prevValue) => ({
                ...prevValue,
                [genre]: event.target.checked,
              }))
            }
          />
          {genre}
        </Label>
      </div>
    ))}
  </Col>
);

const Genres = ({
  checkedGenres,
  setCheckedGenres,
  genresColumnLength,
  genresColumnClassName,
}) => {
  const genresColumns = getGenresColumns(genres, genresColumnLength);

  return (
    <div>
      <Label>Genre</Label>
      <Row className="genresContainer">
        {genresColumns.map((genresColumn, index) => (
          <GenresColumn
            genresColumn={genresColumn}
            checkedGenres={checkedGenres}
            setCheckedGenres={setCheckedGenres}
            genresColumnClassName={genresColumnClassName}
            key={`genresColumn-${index}`}
          />
        ))}
      </Row>
    </div>
  );
};

Genres.propTypes = {
  checkedGenres: PropTypes.object,
  setCheckedGenres: PropTypes.func,
  genresColumnLength: PropTypes.number,
  genresColumnClassName: PropTypes.string,
};

GenresColumn.propTypes = {
  genresColumn: PropTypes.array,
  checkedGenres: PropTypes.object,
  setCheckedGenres: PropTypes.func,
  genresColumnClassName: PropTypes.string,
};

export default Genres;
