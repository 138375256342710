import * as API from 'services/api';

export const getMovieData = async (id) => {
  try {
    const url = `/rest/movie/${id}/`;
    const res = await API.get(url);
    return res.data;
  } catch (error) {
    return { info: '', status: error.response.status };
  }
};

export const replaceMovieData = async (id, data) => {
  // should send all data (use after upload json)
  try {
    const url = `/rest/movie/${id}/`;
    const res = await API.put(url, data);
    return res.data;
  } catch (error) {
    return { info: '', status: error.response.status };
  }
};

export const updateMovieData = async (id, data) => {
  // should send only changed data
  try {
    const url = `/rest/movie/${id}/`;
    const res = await API.patch(url, data);
    return res.data;
  } catch (error) {
    return { info: '', status: error.response.status };
  }
};

export const createMovie = async (data) => {
  try {
    const url = '/rest/movie/';
    const res = await API.post(url, data);
    return res.data;
  } catch (error) {
    return { info: '', status: error.response.status };
  }
};

export const deleteMovie = async (id) => {
  try {
    const url = `/rest/movie/${id}`;
    const res = await API.remove(url);
    return res.data;
  } catch (error) {
    return { info: '', status: error.response.status };
  }
};

export const getImage = async (id) => {
  try {
    const url = `/poster/${id}`;
    const res = await API.get(url);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const uploadImage = async (formData, onUploadProgress) => {
  try {
    const url = '/rest/movie_poster_upload/';
    const config = {
      onUploadProgress,
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data; boundary=something',
      },
    };

    const res = await API.post(url, formData, config);
    return { data: res.data, status: res.status };
  } catch (error) {
    return null;
  }
};

export const deleteImage = async (filmId) => {
  try {
    const url = `/rest/movie_poster_upload/${filmId}/`;
    const res = await API.remove(url);
    return { data: res.data, status: res.status };
  } catch (error) {
    return null;
  }
};

export const importFromFile = async (formData, onUploadProgress) => {
  try {
    const url = '/upload/';
    const config = {
      onUploadProgress,
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data; boundary=something',
      },
    };

    const res = await API.put(url, formData, config);
    return { data: res.data, status: res.status };
  } catch (error) {
    return {
      error: '',
      data: error.response ? error.response.data : '',
      status: error.response ? error.response.status : '',
    };
  }
};

export const getFilmSuggestions = async (q) => {
  try {
    const url = '/search_movies';
    const res = await API.get(url, { q });
    return res.data;
  } catch (error) {
    return [];
  }
};

export const getNameSuggestions = async (q, suggestionType) => {
  try {
    const url = `/search_names/?name=${q}&role_type=${suggestionType}`;
    const res = await API.get(url);
    return res.data;
  } catch (error) {
    return [];
  }
};

export const getCompanySuggestions = async (q, suggestionType) => {
  try {
    const url = `/search_companies?name=${q}&company_type=${suggestionType}`;
    const res = await API.get(url);
    return res.data;
  } catch (error) {
    return [];
  }
};

export const getSimilarFilms = async (uid) => {
  try {
    const url = `/search_similar/${uid}`;
    const res = await API.get(url);
    return res.data;
  } catch (error) {
    return [];
  }
};

export const getFilmTemplate = async () => {
  try {
    const url = `/get_template/`;
    const config = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'blob',
      },
    };
    const res = await API.get(url, null, config);
    return res;
  } catch (error) {
    return {
      error: '',
      data: error.response?.data,
      status: error.response?.status,
    };
  }
};
