import React, { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { logOut, isUserAuthenticated } from 'services/localstorage';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { axiosInstance } from 'services/api';
import { toast } from 'react-toastify';

export const UserContext = createContext({
  user: {},
  updateData: () => {},
  error: undefined,
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [error, setError] = useState(undefined);
  const history = useHistory();
  const location = useLocation();

  const updateData = (data) => {
    setUser({ ...user, ...data });
  };

  const handleError = useCallback(
    (e) => {
      if (
        (location.pathname.includes('2fa') ||
          location.pathname.includes('login')) &&
        e.response?.status === 403
      ) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      if (e?.message === 'Network Error') {
        setError(e);
      } else if (e?.code === 'ECONNABORTED') {
        setError(e);
      } else if (
        e?.response?.status === 404 &&
        !(e?.config?.url.includes('poster') && e?.config?.method === 'get')
      ) {
        setError(e);
      } else if (
        e?.response?.status === 403 ||
        e?.response?.status === 500 ||
        e?.response?.status === 400
      ) {
        setError(e);
      }
    },
    [history, location.pathname],
  );

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use((req) => {
      setError(undefined);
      return req;
    });

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (res) => res,
      (e) => {
        handleError(e);
        throw e;
      },
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [handleError, location.pathname]);

  const handleDismissError = () => setError(undefined);

  const handleOnIdle = () => {
    logOut();
    updateData({ active: false });
    history.push(routes.LOGIN);
  };

  const handleOnActive = () => {};

  const handleOnAction = () => {};

  const { reset, pause } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    stopOnIdle: true,
  });

  useEffect(() => {
    if (user.active || isUserAuthenticated()) {
      reset();
    } else {
      pause();
    }
  }, [user.active, reset, pause]);

  useEffect(() => {
    if (error) {
      let errorMessage = error?.response?.data?.message;

      if (!errorMessage) {
        errorMessage = `Error${error?.status ? ` ${error?.status}` : ''}: ${
          error?.message || 'Something went wrong..'
        }. Please try again in a few minutes.`;
      }

      toast.error(errorMessage, {
        onClose: handleDismissError,
        autoClose: 5000,
        pauseOnFocusLoss: true,
      });
    }
  }, [error, error?.message, error?.status]);

  return (
    <UserContext.Provider value={{ user, updateData, error }}>
      {/* <CustomModal
        open={Boolean(error)}
        handleClose={handleDismissError}
        header="Error"
        submitButtonColor={{ color: 'primary', outline: false }}
        submitButtonText="Dismiss"
        handleSubmit={handleDismissError}
      >
        Something went wrong...
        <br />
        Details: <span style={{ fontWeight: 500 }}>{error?.message}</span>
      </CustomModal> */}
      {children}
      {/* </SnackbarProvider> */}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = { children: PropTypes.any };

export default UserProvider;
