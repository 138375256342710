import constants from 'utils/constants';

export const addToLocalStorage = (
  key,
  value,
  expirationTime = 10 * 60 * 1000,
) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + expirationTime,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getFromLocalStorage = (key, time = 10 * 60 * 1000) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  updateExpirationTime(key, item.value, time);
  return item.value;
};

export const updateExpirationTime = (key, value, time = 10 * 60 * 1000) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + time,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const isUserAuthenticated = () => {
  const authData = getFromLocalStorage(
    constants.authDataLocalStorageKey,
    constants.refreshTokenExpirationTimeout,
  );
  if (authData) {
    return true;
  }
  return false;
};

export const logOut = () => {
  localStorage.removeItem(constants.authDataLocalStorageKey);
  localStorage.removeItem('username');
  localStorage.removeItem(
    constants.advancedSearchPageLastSearchLocalStorageKey,
  );
  localStorage.removeItem(
    constants.filmDetailsPageCollapsibleSectionsLocalStorageKey,
  );
  localStorage.removeItem(constants.filmPageCollapsibleSectionsLocalStorageKey);
  localStorage.removeItem(
    constants.dataAuditPageCollapsibleSectionsLocalStorageKey,
  );
};

export const removeFromLocalStorage = (key) => localStorage.removeItem(key);
