import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PrivateHeader from 'components/navigation/PrivateHeader';
import PrivateFooter from 'components/navigation/PrivateFooter';
import styled from 'styled-components';
import useViewport from 'hooks/useViewport';

const Container = styled.div(
  ({
    headerHeight = 67,
    footerHeight = 0,
    displayFooterBasedOnPageContent = false,
    subtractFooterHeight = false,
  }) => ({
    minHeight: displayFooterBasedOnPageContent
      ? `auto`
      : `calc(100vh - ${headerHeight}px${
          subtractFooterHeight ? ` - ${footerHeight}px` : ''
        })`,
  }),
);

export const PrivateLayout = ({
  children,
  displaySearchFieldComponent,
  displayFooterBasedOnPageContent,
  providePublicBrandInfoOnly,
  subtractFooterHeight = false,
}) => {
  const { setFooterHeight } = useViewport();
  const [header] = useState(67);
  const [footer, setFooter] = useState(0);

  const footerRef = useRef(null);

  useEffect(() => {
    document.onreadystatechange = () => {
      setFooter(footerRef.current?.getBoundingClientRect().height);
      setFooterHeight(footerRef.current?.getBoundingClientRect().height);
    };
  }, [setFooterHeight]);

  useEffect(() => {
    const handleResize = () => {
      const innerFooter = footerRef.current?.getBoundingClientRect().height;
      if (footer !== innerFooter) {
        setFooter(innerFooter);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [footer, header]);

  return (
    <>
      <PrivateHeader
        displaySearchFieldComponent={displaySearchFieldComponent}
        providePublicBrandInfoOnly={providePublicBrandInfoOnly}
      />
      <Container
        headerHeight={header}
        footerHeight={footer}
        displayFooterBasedOnPageContent={displayFooterBasedOnPageContent}
        subtractFooterHeight={subtractFooterHeight}
      >
        {children}
      </Container>
      <PrivateFooter customRef={footerRef} />
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
  displaySearchFieldComponent: PropTypes.any,
  displayFooterBasedOnPageContent: PropTypes.any,
  providePublicBrandInfoOnly: PropTypes.bool,
  subtractFooterHeight: PropTypes.bool,
};
