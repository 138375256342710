export const parseAwards = (arrayToParse = []) => {
  const result = {
    'Awards Received': [],
    'Nominated For': [],
  };

  arrayToParse.forEach((item) => {
    if (item.status === 'nominated') {
      result['Nominated For'] = [...result['Nominated For'], item];
    } else {
      result['Awards Received'] = [...result['Awards Received'], item];
    }
  });

  return result;
};
