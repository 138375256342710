import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useViewport from 'hooks/useViewport';
import { routes } from 'routes/routes';
import { Bell } from 'react-feather';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  ButtonGroup,
  Tooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import commonConstants from 'utils/constants';
import './NotificationsPopup.scss';
import SingleNotification from './SingleNotification';

const NotificationsPopup = ({ handleNotificationsIconLinkClick }) => {
  const [notificationsPopupOpen, setNotificationsPopupOpen] = useState(false);

  const toggleNotificationsPopup = () => {
    setNotificationsPopupOpen((prevState) => !prevState);
    handleNotificationsIconLinkClick();
  };

  const defaultNotifications = [
    {
      notificationId: 'aaa',
      notificationType: commonConstants.notificationTypes[0],
      summary: 'A treshold of 23,454 films was exceeded.',
      timeDifference: '10 minutes ago',
      details:
        'The number of films with 20% of author changed in 5 days reached 31,777, which exceeds the defined treshold of 23,454 by 8,323 films.',
    },
    {
      notificationId: 'bbb',
      notificationType: commonConstants.notificationTypes[1],
      summary: 'A treshold of 23,454 films was reached.',
      timeDifference: '1 hour ago',
      details:
        'The number of films with 20% of author changed in 5 days reached the defined treshold of 23,454.',
    },
  ];

  const [userNotifications, setUserNotifications] = useState(
    defaultNotifications,
  );

  const [userHasUnreadNotifications, setUserHasUnreadNotifications] = useState(
    defaultNotifications.length > 0,
  );

  const dismissUserNotification = (notificationId) => {
    const notDismissedNotifications = userNotifications.filter(
      (item) => item.notificationId !== notificationId,
    );

    if (!notDismissedNotifications.length) {
      setNotificationsPopupOpen(false);
    }

    setUserNotifications([...notDismissedNotifications]);
    setUserHasUnreadNotifications(notDismissedNotifications.length > 0);
  };

  const [notificationsTooltipOpen, setNotificationsTooltipOpen] = useState(
    false,
  );

  const toggleNotificationsTooltip = () =>
    setNotificationsTooltipOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={notificationsPopupOpen}
      toggle={() => toggleNotificationsPopup()}
      className="notificationsPopup"
    >
      <DropdownToggle
        className={`notificationsPopupToggle ${
          notificationsPopupOpen ? 'activeIconLink' : ''
        }`}
        disabled={!userHasUnreadNotifications}
        id="privateHeaderNotificationsIcon"
      >
        <span
          className={
            userHasUnreadNotifications ? 'unread-notifications-circle' : ''
          }
        >
          <Bell size={16}></Bell>
        </span>
      </DropdownToggle>
      <Tooltip
        placement="bottom"
        isOpen={notificationsTooltipOpen}
        target="privateHeaderNotificationsIcon"
        toggle={toggleNotificationsTooltip}
      >
        Notifications
      </Tooltip>
      <DropdownMenu right className="notifications custom-accordion">
        {userNotifications.map((userNotification) => (
          <SingleNotification
            key={userNotification.notificationId}
            notificationType={userNotification.notificationType}
            summary={userNotification.summary}
            timeDifference={userNotification.timeDifference}
            details={userNotification.details}
            dismissNotification={() =>
              dismissUserNotification(userNotification.notificationId)
            }
          ></SingleNotification>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

NotificationsPopup.propTypes = {
  handleNotificationsIconLinkClick: PropTypes.func,
};

export default NotificationsPopup;
