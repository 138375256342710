import styled from 'styled-components';
import { Navbar, NavLink, DropdownToggle } from 'reactstrap';
import { Link as ScrollLink } from 'react-scroll';
import { BLACK, DARK_GREY, DARK_GREY_1, MIDDLE_GREY } from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import { Link } from 'react-router-dom';

const IconsColor = '#8a8a8a';
const hoverIconsColor = '#000';

export const NavbarContainer = styled.div(() => ({
  position: 'sticky',
  top: '0',
  zIndex: '10',
}));

export const LinkWrapper = styled(Link)(() => ({}));

export const IconLink = styled(Link)`
  display: flex;
  padding: 2px 8px 2px 8px;
  font-size: 1rem;
  color: ${IconsColor};
  cursor: pointer;
  &:hover {
    color: ${hoverIconsColor};
    text-decoration: none;
  }
`;

export const NavbarComponent = styled(Navbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  padding: '10px 16px',
}));

export const IconsContainer = styled(Navbar)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
}));

export const NavLinkComponent = styled(NavLink)(() => ({
  display: 'flex',
  padding: '2px 8px 2px 8px',
  fontSize: '1rem',
  color: IconsColor,
  cursor: 'pointer',
  '&:hover': {
    color: hoverIconsColor,
  },
}));

export const DropdownToggleComponent = styled(DropdownToggle)(() => ({
  padding: '.2rem .5rem',
  color: `${IconsColor} !important`,
  '&:hover': {
    color: `${hoverIconsColor} !important`,
  },
}));

export const UserIconComponent = styled(DropdownToggleComponent)(() => ({
  width: '2.5rem',
  height: '2.5rem',
  padding: '.5rem',
  display: 'flex',
  justifyContent: 'center',
}));

export const PrivateFooterWrapper = styled.footer(
  ({ justifyContent, flexDirection }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'space-between',
    flexDirection,
    backgroundColor: '#fff',
    padding: '1rem',
  }),
);

export const PrivateFooterContainer = styled.div(
  ({ order, flexDirection }) => ({
    display: 'flex',
    order,
    flexDirection,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    color: DARK_GREY_1,
    fontWeight: 500,
  }),
);

export const PrivateFooterLinkComponent = styled(NavLink)(
  ({ display, padding }) => ({
    display,
    padding,
    color: BLACK,
    fontWeight: 400,
    '&:hover': {
      color: BLACK,
      textDecoration: 'underline',
    },
  }),
);

export const PublicFooterWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 60,
  padding: '1rem',
}));

export const PublicFooterContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.8rem',
  color: '#8a8a8a',
}));

export const PublicFooterLinkComponent = styled(NavLink)(({ display }) => ({
  display,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const CustomScrollLink = styled(ScrollLink)(() => ({
  display: 'flex',
  color: MIDDLE_GREY,
  padding: 8,
  cursor: 'pointer',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.5,
  '&.active': {
    color: BLACK,
    '&:hover': {
      color: BLACK,
    },
  },
  '&:hover': {
    color: DARK_GREY,
    textDecoration: 'none',
  },
  [breakpoints.medium]: {
    padding: '8px 0',
  },
}));

export const CustomScrollLinkSmall = styled(CustomScrollLink)(() => ({
  fontSize: '0.86rem',
  [breakpoints.medium]: {
    padding: 8,
  },
}));
