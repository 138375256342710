import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Collapse,
  Container,
} from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { getFromLocalStorage } from 'services/localstorage';
import constants from 'utils/constants';
import Budget from './Budget';
import DateRange from './DateRange';
import Genres from './Genres';
import {
  getTextAfterSearchInLibrarySectionClick,
  getLibrarySearchType,
  getLibrarySearchValue,
  getNullableNumberFromString,
  getNullableDateFromString,
  getEncodedUriComponentFromObject,
  removeBlankAttributes,
  getNumberInUSALocaleOrDefaultString,
} from './utils';
import {
  SEARCH_IN_FOLDERS_TEXT,
  SEARCH_IN_FILMS_TEXT,
  advancedSearchFilmsQueryFields,
  advancedSearchDateFormat,
  genres,
} from './constants';
import './SearchField.scss';
import 'react-datetime/css/react-datetime.css';

const SearchField = () => {
  const history = useHistory();
  const location = useLocation();

  const [
    searchFilmsConfigDropdownOpen,
    setSearchFilmsConfigDropdownOpen,
  ] = useState(false);

  const [
    searchFilmsSearchIconTooltipOpen,
    setSearchFilmsSearchIconTooltipOpen,
  ] = useState(false);

  const [
    searchFilmsClearSearchInputButtonTooltipOpen,
    setSearchFilmsClearSearchInputButtonTooltipOpen,
  ] = useState(false);

  const [searchFilmsInputValue, setSearchFilmsInputValue] = useState('');

  const searchFilmsInputRef = useRef(null);

  const [
    advancedSearchFilmsConfigDropdownOpen,
    setAdvancedSearchFilmsConfigDropdownOpen,
  ] = useState(false);

  const toggleSearchFilmsSearchIconTooltip = () =>
    setSearchFilmsSearchIconTooltipOpen((prevState) => !prevState);

  const toggleSearchFilmsClearSearchInputButtonTooltip = () =>
    setSearchFilmsClearSearchInputButtonTooltipOpen((prevState) => !prevState);

  const advancedSearchConfigDropdownHasData = () =>
    productionBudgetValue.min !== '' ||
    productionBudgetValue.max !== '' ||
    advancedSearchStartDate !== '' ||
    advancedSearchEndDate !== '' ||
    genres.some((genre) => checkedGenres[genre]);

  const toggleSearchFilmsConfigDropdown = () => {
    if (searchFilmsConfigDropdownOpen) {
      setSearchFilmsConfigDropdownOpen(false);
    } else {
      setSearchFilmsConfigDropdownOpen(true);

      const advancedSearchDropdownHasData = advancedSearchConfigDropdownHasData();

      setAdvancedSearchFilmsConfigDropdownOpen(advancedSearchDropdownHasData);
    }
  };

  const toggleAdvancedSearchFilmsConfigDropdown = () =>
    setAdvancedSearchFilmsConfigDropdownOpen((prevState) => !prevState);

  const clearSearchFilmsInput = () => {
    setSearchFilmsInputValue('');
    setSearchFilmsClearSearchInputButtonTooltipOpen(false);
  };

  const [advancedSearchEndDate, setAdvancedSearchEndDate] = useState('');

  const [advancedSearchStartDate, setAdvancedSearchStartDate] = useState('');

  const [
    advancedSearchEndDateInputValue,
    setAdvancedSearchEndDateInputValue,
  ] = useState('');

  const [
    advancedSearchStartDateInputValue,
    setAdvancedSearchStartDateInputValue,
  ] = useState('');

  const [checkedGenres, setCheckedGenres] = useState({});

  const defaultBudgetValue = {
    min: '',
    max: '',
  };

  const [productionBudgetValue, setProductionBudgetValue] = useState(
    defaultBudgetValue,
  );

  const handleSearchInLibrarySectionClick = (searchInLibrarySectionText) => {
    const updatedSearchFilmsInputValue = getTextAfterSearchInLibrarySectionClick(
      searchInLibrarySectionText,
      searchFilmsInputValue,
    );

    setSearchFilmsInputValue(updatedSearchFilmsInputValue);

    searchFilmsInputRef.current.focus();
  };

  const resetAdvancedSearchFields = () => {
    setAdvancedSearchStartDate('');

    setAdvancedSearchStartDateInputValue('');

    setAdvancedSearchEndDate('');

    setAdvancedSearchEndDateInputValue('');

    setSearchFilmsInputValue('');

    setCheckedGenres({});

    setProductionBudgetValue(defaultBudgetValue);
  };

  const handleProductionBudgetChange = (value, type) => {
    const formattedValue = getNumberInUSALocaleOrDefaultString(value);
    const updatedProductionBudget = {
      ...productionBudgetValue,
      [type]: formattedValue,
    };
    setProductionBudgetValue(updatedProductionBudget);
  };

  const createSearchQueryParams = () => {
    let searchQueryParams = {
      [advancedSearchFilmsQueryFields.advanced_search]: {
        [advancedSearchFilmsQueryFields.production_budget]: {
          min: getNullableNumberFromString(
            productionBudgetValue.min.replace(/,/g, ''),
          ),
          max: getNullableNumberFromString(
            productionBudgetValue.max.replace(/,/g, ''),
          ),
        },
      },
    };

    searchQueryParams = removeBlankAttributes(searchQueryParams);

    const librarySearchValue = getLibrarySearchValue(searchFilmsInputValue);
    if (librarySearchValue) {
      searchQueryParams[advancedSearchFilmsQueryFields.library_search] = {
        value: librarySearchValue,
      };

      const librarySearchType = getLibrarySearchType(searchFilmsInputValue);
      if (librarySearchType) {
        searchQueryParams[
          advancedSearchFilmsQueryFields.library_search
        ].type = librarySearchType;
      }
    }

    const dateRangeStart = getNullableDateFromString(
      advancedSearchStartDate,
      advancedSearchDateFormat,
    );
    if (dateRangeStart) {
      searchQueryParams[advancedSearchFilmsQueryFields.advanced_search][
        advancedSearchFilmsQueryFields.date_range
      ] = {
        start: dateRangeStart,
      };
    }

    const dateRangeEnd = getNullableDateFromString(
      advancedSearchEndDate,
      advancedSearchDateFormat,
    );
    if (dateRangeEnd) {
      const dateRangeObj =
        searchQueryParams[advancedSearchFilmsQueryFields.advanced_search][
          advancedSearchFilmsQueryFields.date_range
        ];

      searchQueryParams[advancedSearchFilmsQueryFields.advanced_search][
        advancedSearchFilmsQueryFields.date_range
      ] = {
        ...dateRangeObj,
        end: dateRangeEnd,
      };
    }

    searchQueryParams[advancedSearchFilmsQueryFields.advanced_search][
      advancedSearchFilmsQueryFields.genre
    ] = genres.filter((genre) => !!checkedGenres[genre]);

    return searchQueryParams;
  };

  const getSearchFormFieldValues = () => {
    const searchFormFieldValues = {
      searchFilmsInputValue,
      advancedSearchStartDate,
      advancedSearchEndDate,
      advancedSearchStartDateInputValue,
      advancedSearchEndDateInputValue,
      checkedGenres,
      productionBudgetValue,
    };

    return searchFormFieldValues;
  };

  const compareSearchFormFieldsWithLastSearchFromAdvancedSearchPage = (
    searchFormFieldValues,
    lastSearchAdvancedSearchPageFieldValues,
  ) =>
    searchFormFieldValues.searchFilmsInputValue ===
      lastSearchAdvancedSearchPageFieldValues.searchFilmsInputValue &&
    searchFormFieldValues.advancedSearchStartDate ===
      lastSearchAdvancedSearchPageFieldValues.advancedSearchStartDate &&
    searchFormFieldValues.advancedSearchEndDate ===
      lastSearchAdvancedSearchPageFieldValues.advancedSearchEndDate &&
    searchFormFieldValues.advancedSearchStartDateInputValue ===
      lastSearchAdvancedSearchPageFieldValues.advancedSearchStartDateInputValue &&
    searchFormFieldValues.advancedSearchEndDateInputValue ===
      lastSearchAdvancedSearchPageFieldValues.advancedSearchEndDateInputValue &&
    searchFormFieldValues.productionBudgetValue.min ===
      lastSearchAdvancedSearchPageFieldValues.productionBudgetValue.min &&
    searchFormFieldValues.productionBudgetValue.max ===
      lastSearchAdvancedSearchPageFieldValues.productionBudgetValue.max &&
    !genres.some(
      (genre) =>
        searchFormFieldValues.checkedGenres[genre] !==
        lastSearchAdvancedSearchPageFieldValues.checkedGenres[genre],
    );

  const handleSearchFormSubmit = (event) => {
    if (event) event.preventDefault();

    const searchQueryParams = createSearchQueryParams();

    const encodedQueryParams = getEncodedUriComponentFromObject(
      searchQueryParams,
    );

    const searchFormFieldValues = getSearchFormFieldValues();

    const locationState = { searchFormFieldValues, encodedQueryParams };

    history.push(`${routes.SEARCH_RESULTS}`, locationState);
    history.go(0);
  };

  useEffect(() => {
    if (location.state) {
      const { searchFormFieldValues } = location.state;

      if (searchFormFieldValues) {
        setSearchFilmsInputValue(searchFormFieldValues.searchFilmsInputValue);

        setAdvancedSearchStartDate(
          searchFormFieldValues.advancedSearchStartDate,
        );

        setAdvancedSearchEndDate(searchFormFieldValues.advancedSearchEndDate);

        setAdvancedSearchStartDateInputValue(
          searchFormFieldValues.advancedSearchStartDateInputValue,
        );

        setAdvancedSearchEndDateInputValue(
          searchFormFieldValues.advancedSearchEndDateInputValue,
        );

        setCheckedGenres(searchFormFieldValues.checkedGenres);

        setProductionBudgetValue(searchFormFieldValues.productionBudgetValue);
      }
    }
  }, [location]);

  const handleMoreOptionsLinkClick = (event) => {
    event.preventDefault();

    const searchFormFieldValues = getSearchFormFieldValues();

    const lastSearchAdvancedSearchPageFieldValues = getFromLocalStorage(
      constants.advancedSearchPageLastSearchLocalStorageKey,
      constants.rememberAdvancedSearchPageLastSearchTimeout,
    );

    const fieldsAreSame = lastSearchAdvancedSearchPageFieldValues
      ? compareSearchFormFieldsWithLastSearchFromAdvancedSearchPage(
          searchFormFieldValues,
          lastSearchAdvancedSearchPageFieldValues,
        )
      : false;

    const advancedSearchPageLocationState = {
      advancedSearchPageFieldValuesFromSearchForm: fieldsAreSame
        ? null
        : searchFormFieldValues,
    };

    history.push(`${routes.ADVANCED_SEARCH}`, advancedSearchPageLocationState);
  };

  const openSearchForm = () => {
    setSearchFilmsConfigDropdownOpen(true);

    const advancedSearchDropdownHasData = advancedSearchConfigDropdownHasData();

    setAdvancedSearchFilmsConfigDropdownOpen(advancedSearchDropdownHasData);
  };

  return (
    <Form
      className="searchFilmsForm col-md-10 col-lg-8 col-xl-7"
      onReset={() => resetAdvancedSearchFields()}
    >
      <FormGroup>
        <InputGroup className="searchFilmsInputGroup">
          <Button
            id="searchFilmsSearchIconButton"
            className="icon-search bg-transparent border-0 p-0 text-secondary outline-0 searchFilmsSearchIconButton"
            onClick={handleSearchFormSubmit}
          ></Button>
          <Tooltip
            placement="top"
            isOpen={searchFilmsSearchIconTooltipOpen}
            target="searchFilmsSearchIconButton"
            toggle={toggleSearchFilmsSearchIconTooltip}
          >
            Search
          </Tooltip>
          <Input
            type="text"
            name="search"
            id="search"
            placeholder="Search film titles, actors, directors, producers, genre, budget and more..."
            className="searchFilmsInput"
            onChange={(e) => setSearchFilmsInputValue(e.target.value)}
            onClick={openSearchForm}
            value={searchFilmsInputValue}
            innerRef={searchFilmsInputRef}
            autoComplete="off"
          />
          {searchFilmsInputValue.length > 0 && (
            <div>
              <Button
                type="reset"
                id="searchFilmsClearSearchInputButton"
                className="icon-x bg-transparent border-0 px-1 py-0 text-secondary outline-0 searchFilmsClearSearchInputButton"
                onClick={() => clearSearchFilmsInput()}
              ></Button>
              <Tooltip
                placement="top"
                isOpen={searchFilmsClearSearchInputButtonTooltipOpen}
                target="searchFilmsClearSearchInputButton"
                toggle={toggleSearchFilmsClearSearchInputButtonTooltip}
              >
                Clear search
              </Tooltip>
            </div>
          )}
          <Dropdown
            isOpen={searchFilmsConfigDropdownOpen}
            toggle={() => toggleSearchFilmsConfigDropdown()}
            className="searchFilmsConfigDropDown"
          >
            <DropdownToggle
              caret
              className="searchFilmsConfigDropDownButton"
            ></DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header>Search in Library:</DropdownItem>
              <DropdownItem
                className="icon-folder close-dd"
                onClick={() =>
                  handleSearchInLibrarySectionClick(SEARCH_IN_FOLDERS_TEXT)
                }
                toggle={false}
              >
                Folders
              </DropdownItem>
              <DropdownItem
                className="icon-film close-dd"
                onClick={() =>
                  handleSearchInLibrarySectionClick(SEARCH_IN_FILMS_TEXT)
                }
                toggle={false}
              >
                Films
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                toggle={false}
                onClick={() => toggleAdvancedSearchFilmsConfigDropdown()}
                className="advancedSearchFilmsConfigDropDownButton"
              >
                <span
                  className={
                    advancedSearchFilmsConfigDropdownOpen
                      ? 'icon-chevron-up'
                      : 'icon-chevron-down'
                  }
                />
                Advanced Search
              </DropdownItem>
              {advancedSearchFilmsConfigDropdownOpen && (
                <DropdownItem divider />
              )}
              <Collapse
                isOpen={advancedSearchFilmsConfigDropdownOpen}
                className="advancedSearchFieldsCollapse"
              >
                <Container className="advancedSearchFieldsContainer">
                  <fieldset>
                    <Budget
                      labelValue="Production Budget"
                      handleBudgetChange={handleProductionBudgetChange}
                      inputGroupClassName="input-group-sm"
                      budgetValue={productionBudgetValue}
                      colClassName="form-group col-sm-6"
                    />
                    <DateRange
                      advancedSearchStartDate={advancedSearchStartDate}
                      setAdvancedSearchStartDate={setAdvancedSearchStartDate}
                      advancedSearchStartDateInputValue={
                        advancedSearchStartDateInputValue
                      }
                      setAdvancedSearchStartDateInputValue={
                        setAdvancedSearchStartDateInputValue
                      }
                      advancedSearchEndDate={advancedSearchEndDate}
                      setAdvancedSearchEndDate={setAdvancedSearchEndDate}
                      advancedSearchEndDateInputValue={
                        advancedSearchEndDateInputValue
                      }
                      setAdvancedSearchEndDateInputValue={
                        setAdvancedSearchEndDateInputValue
                      }
                      inputGroupClassName="input-group-sm"
                      labelText="Date Range"
                      colClassName="form-group col-sm-6"
                    />
                    <Genres
                      checkedGenres={checkedGenres}
                      setCheckedGenres={setCheckedGenres}
                      genresColumnLength={5}
                      genresColumnClassName="col-6 col-md-3"
                    />
                    <div className="text-center py-2 advancedSearchSubmitResetButtonsContainer">
                      <Link
                        to={routes.ADVANCED_SEARCH}
                        onClick={handleMoreOptionsLinkClick}
                      >
                        More options
                      </Link>
                      <hr />
                      <Button
                        type="reset"
                        className="btn btn-sm btn-outline-primary"
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={(event) => handleSearchFormSubmit(event)}
                      >
                        Submit
                      </Button>
                    </div>
                  </fieldset>
                </Container>
              </Collapse>
            </DropdownMenu>
          </Dropdown>
        </InputGroup>
      </FormGroup>
      <input
        type="submit"
        className="fakeSafariSubmitInput"
        onClick={(event) => handleSearchFormSubmit(event)}
      />
    </Form>
  );
};

export default SearchField;
