export const SEARCH_IN_FOLDERS_TEXT = 'lfo: ';
export const SEARCH_IN_FOLDERS_API_TEXT = 'lfo';
export const SEARCH_IN_FILMS_TEXT = 'lfi: ';
export const SEARCH_IN_FILMS_API_TEXT = 'lfi';

export const advancedSearchDateFormat = 'MM/DD/YYYY';

export const genres = [
  'Action',
  'Adventure',
  'Animation',
  'Biography',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'History',
  'Horror',
  'Music',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Sport',
  'Thriller',
  'War',
  'Western',
];

export const advancedSearchFilmsQueryFields = {
  library_search: 'library_search',
  advanced_search: 'advanced_search',
  production_budget: 'production_budget',
  marketing_budget: 'marketing_budget',
  date_range: 'date_range',
  genre: 'movie_genre',
  box_office_revenue: 'box_office_revenue',
  production: 'production',
  save_search: 'save_search',
  include_dustribution_data: 'include_dustribution_data',
  directors: 'directors',
  actors: 'actors',
  authors: 'authors',
  production_companies: 'production_companies',
  distributors: 'distributors',
  financiers: 'financiers',
  additional_criterias: 'additional_criterias',
  producers: 'producers',
};
