export const GREY_BACKGROUND = '#f8f9fa';
export const GREY_BACKGROUND_2 = '#f7f7f7';
export const GREY_BACKGROUND_3 = '#e2e6ea';
export const GREY_BORDER = '#dee2e6';
export const MIDDLE_GREY = 'rgba(0, 0, 0, 0.5)';
export const DARK_GREY = 'rgba(0,0,0,0.7)';
export const DARK_GREY_1 = '#6c757d';
export const LIGHT_GREY_1 = '#8a8a8a';
export const LIGHT_GREY_2 = '#e9ecef';
export const BLACK = '#000000';
export const BLACK_1 = '#212529';
export const WHITE = '#ffffff';
export const RED = '#DD1144';
export const BLUE = '#007bff';
export const DARK_BLUE = '#0056b3';
