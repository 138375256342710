import React, { useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import headerLogo from 'images/logo-cv.png';
import useQuery from 'hooks/useQuery';

const Header = styled.header(() => ({
  h1: {
    fontSize: 14,
    span: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const Footer = styled.footer(() => ({
  small: {
    fontSize: 9,
  },
}));

export const PrivatePrintLayout = ({ children }) => {
  const query = useQuery();

  const date = query.get('date');

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 300,
      smooth: true,
    });
  };

  useEffect(() => {
    const printEvent = () => {
      animateScroll.scrollToTop({
        duration: 0,
        smooth: false,
      });
    };

    window.addEventListener('beforeprint', printEvent);

    return () => {
      window.removeEventListener('beforeprint', printEvent);
    };
  }, []);

  return (
    <div className="container">
      <Header className="container fixed-top top-0">
        <h1 className="text-right text-small text-muted text-uppercase m-0 pt-4">
          <span onClick={scrollToTop} className="text-dark">
            Analytics Report
          </span>{' '}
          • {date}
        </h1>
      </Header>

      {children}

      <Footer className="container fixed-bottom bg-white">
        <div className="d-flex align-items-center justify-content-between text-center text-small text-muted py-2 border-top">
          <img
            src={headerLogo}
            className="logo"
            alt="Footer Logo"
            height="20px"
          />

          <small>
            © 2024 CINEMATIC VENTURE PARTNERS FUND, LP. FOR ACCREDITED INVESTORS
            ONLY. NOT FOR PUBLIC DISTRIBUTION.
          </small>
        </div>
      </Footer>
    </div>
  );
};

PrivatePrintLayout.propTypes = {
  children: PropTypes.any,
};
