const SIDEBAR_ITEMS = {
  Overview: {
    short: 'overview',
    title: 'Overview',
    nodes: {
      GeneralInfo: { title: 'General Info' },
      ReleaseInfo: { title: 'Release Info' },
      ShootingLocations: { title: 'Shooting Locations' },
      Financials: { title: 'Financials' },
      Miscellaneous: { title: 'Miscellaneous' },
    },
  },
  SimilarFilms: { short: 'similar', title: 'Similar Films' },
  Cast: {
    short: 'cast',
    title: 'Full Cast & Crew',
    nodes: {
      Directors: { title: 'Director(s)' },
      Writers: { title: 'Writers' },
      Producers: { title: 'Producers' },
      Actors: { title: 'Actors' },
      Editors: { title: 'Editor(s)' },
      CastingDirectors: { title: 'Casting Director(s)' },
      Cinematographers: { title: 'Cinematographer(s)' },
      Composers: { title: 'Composer(s)' },
      ProductionDesigners: { title: 'Production Designer(s)' },
      ArtDirectors: { title: 'Art Director(s)' },
      Animators: { title: 'Animators' },
    },
  },
  CompanyCredits: {
    short: 'credits',
    title: 'Company Credits',
    nodes: {
      ProductionCompanies: { title: 'Production Companies' },
      SpecialEffects: { title: 'Special Effects' },
      AdditionalCompanies: { title: 'Additional Companies' },
    },
  },
  Awards: {
    short: 'awards',
    title: 'Awards',
    nodes: {
      AwardsReceived: { title: 'Awards Received' },
      NominatedFor: { title: 'Nominated For' },
    },
  },
  Distributors: {
    short: 'distributors',
    title: 'Distributors',
    nodes: {
      Worldwide: { title: 'Worldwide' },
      NorthAmerica: { title: 'North America' },
      UK: { title: 'UK' },
      Europe: { title: 'Europe' },
      FarEast: { title: 'Far East' },
      LatinAmerica: { title: 'Latin America' },
      CentralAmerica: { title: 'Central America' },
      MiddleEast: { title: 'Middle East' },
      EasternEurope: { title: 'Eastern Europe' },
    },
  },
  DistributionInfo: {
    short: 'distributors-info',
    title: 'Distribution Info',
    nodes: {
      Section1: { title: 'Section 1' },
      Section2: { title: 'Section 2' },
      Section3: { title: 'Section 3' },
    },
  },
  Downloadables: { short: 'downloadables', title: 'Downloadables' },
};

export default SIDEBAR_ITEMS;
