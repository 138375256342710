const aspectRatio = [
  '1.18:1',
  '1.33:1',
  '1.66:1',
  '1.75:1',
  '1.77:1',
  '1.78:1',
  '1.85:1',
  '1.9:1',
  '2:1',
  '2.2:1',
  '2.35:1',
  '2.39:1',
  '2.55:1',
  '5:1',
];
const ratingOptions = ['G', 'PG', 'PG-13', 'R', 'NC-17'];
const genres = [
  'Action',
  'Adventure',
  'Animation',
  'Biography',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'History',
  'Horror',
  'Music',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Sport',
  'Thriller',
  'War',
  'Western',
];

const countries = [
  'Worldwide',
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cape Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos Islands',
  'Colombia',
  'Comoros',
  'Democratic Republic of the Congo',
  'Republic of the Congo',
  'Cook Islands',
  'Costa Rica',
  `Cote d'Ivoire`,
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'South Korea',
  'North Korea',
  'Kuwait',
  'Kyrgyzstan',
  `Lao People's Democratic Republic`,
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (British)',
  'U.S. Virgin Islands',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const territories = {
  'North America': ['United States', 'Canada', 'French Canada'],
  UK: [
    'Australia',
    'New Zealand',
    'South Africa',
    'East Africa',
    'West Africa',
    'West Indies',
    'United Kingdom',
  ],
  Europe: [
    'Germany',
    'Austria',
    'Switzerland',
    'France',
    'Italy',
    'Spain',
    'Benelux',
    'Scandinavia',
    'Iceland',
    'Portugal',
    'Greece',
    'Finland',
    'Sweden',
  ],
  'Far East': [
    'Japan',
    'South Korea',
    'Taiwan',
    'Hong Kong',
    'Singapore',
    'Brunei',
    'Malaysia',
    'Indonesia',
    'Philippines',
    'Thailand',
    'India',
    'Sri Lanka',
    'Pakistan',
    'China',
  ],
  'Latin America': [
    'Argentina',
    'Paraguay',
    'Uruguay',
    'Brazil',
    'Mexico',
    'Chile',
    'Colombia',
    'Venezuela',
  ],
  'Central America': ['Ecuador', 'Peru', 'Bolivia', 'Dominican Republic'],
  'Middle East': ['Lebanon', 'Saudi Arabia', 'Turkey', 'Israel'],
  'Eastern Europe': [
    'Bulgaria',
    'Czech Republic',
    'Hungary',
    'Poland',
    'Croatia',
    'Serbia',
    'CIS',
    'Romania',
  ],
};

const territoriesMapping = {
  Worldwide: 'Worldwide',
  'North America': 'North America',
  UK: 'UK',
  Europe: 'Europe',
  'Far East': 'Far East',
  'Latin America': 'Latin America',
  'Central America': 'Central America',
  'Middle East': 'Middle East',
  'Eastern Europe': 'Eastern Europe',
};

const libraryHeaderColumns = [
  { columnName: 'ID', generalSortingNotSupported: false },
  { columnName: 'Name', generalSortingNotSupported: false },
  { columnName: 'Date Added', generalSortingNotSupported: false },
  { columnName: 'Date Modified', generalSortingNotSupported: false },
  { columnName: 'Release Date', generalSortingNotSupported: false },
  { columnName: 'Genre', generalSortingNotSupported: true },
  { columnName: 'Budget', generalSortingNotSupported: false },
  { columnName: 'Profit', generalSortingNotSupported: false },
  { columnName: 'Director', generalSortingNotSupported: true },
  { columnName: 'Actor Lead 1', generalSortingNotSupported: true },
  { columnName: 'Actor Lead 2', generalSortingNotSupported: true },
  { columnName: 'Data Type', generalSortingNotSupported: true },
];

const searchResultsHeaderColumns = [
  { columnName: 'ID', generalSortingNotSupported: false },
  { columnName: 'Name', generalSortingNotSupported: false },
  { columnName: 'Date Added', generalSortingNotSupported: false },
  { columnName: 'Date Modified', generalSortingNotSupported: false },
  { columnName: 'Release Date', generalSortingNotSupported: false },
  { columnName: 'Genre', generalSortingNotSupported: true },
  { columnName: 'Budget', generalSortingNotSupported: false },
  { columnName: 'Profit', generalSortingNotSupported: false },
  { columnName: 'Director', generalSortingNotSupported: true },
  { columnName: 'Actor Lead 1', generalSortingNotSupported: true },
  { columnName: 'Actor Lead 2', generalSortingNotSupported: true },
];

const distributorsHeaderColumns = [
  { columnName: 'Distributor', generalSortingNotSupported: false },
  { columnName: 'Films', generalSortingNotSupported: false },
  { columnName: 'Years', generalSortingNotSupported: false },
  { columnName: 'Genre', generalSortingNotSupported: true },
  { columnName: 'Countries', generalSortingNotSupported: true },
  { columnName: 'Profit', generalSortingNotSupported: false },
];

const distributorFilmsHeaderColumns = [
  { columnName: 'Film', generalSortingNotSupported: false },
  { columnName: 'Year', generalSortingNotSupported: false },
  { columnName: 'Genre', generalSortingNotSupported: true },
  { columnName: 'Countries', generalSortingNotSupported: true },
  { columnName: 'Profit', generalSortingNotSupported: false },
];

const awardsStatuses = ['won', 'silver', 'bronze', 'nominated'];

const producerRoles = [
  'Producer',
  'Executive Producer',
  'Co-producer',
  'Associate Producer',
  'Line Producer',
  'Assistant Producer',
  'Supervising Producer',
  'Coordinating Producer',
];

const actorTypes = ['Star', 'Actor'];

const editorRoles = ['Editor', 'Assistant Editor'];

const moreLessOptions = ['more than', 'less than'];

const inputTypeOptions = [
  'Input type 1',
  'Input type 2',
  'Input type 3',
  'Input type 4',
  'Input type 5',
];

const inputTypeOptionsEnum = {
  [inputTypeOptions[0]]: 1,
  [inputTypeOptions[1]]: 2,
  [inputTypeOptions[2]]: 3,
  [inputTypeOptions[3]]: 4,
  [inputTypeOptions[4]]: 5,
};

const yearsRange = [
  '1879',
  '1880',
  '1881',
  '1882',
  '1883',
  '1884',
  '1885',
  '1886',
  '1887',
  '1888',
  '1889',
  '1890',
  '1891',
  '1892',
  '1893',
  '1894',
  '1895',
  '1896',
  '1897',
  '1898',
  '1899',
  '1900',
  '1901',
  '1902',
  '1903',
  '1904',
  '1905',
  '1906',
  '1907',
  '1908',
  '1909',
  '1910',
  '1911',
  '1912',
  '1913',
  '1914',
  '1915',
  '1916',
  '1917',
  '1918',
  '1919',
  '1920',
  '1921',
  '1922',
  '1923',
  '1924',
  '1925',
  '1926',
  '1927',
  '1928',
  '1929',
  '1930',
  '1931',
  '1932',
  '1933',
  '1934',
  '1935',
  '1936',
  '1937',
  '1938',
  '1939',
  '1940',
  '1941',
  '1942',
  '1943',
  '1944',
  '1945',
  '1946',
  '1947',
  '1948',
  '1949',
  '1950',
  '1951',
  '1952',
  '1953',
  '1954',
  '1955',
  '1956',
  '1957',
  '1958',
  '1959',
  '1960',
  '1961',
  '1962',
  '1963',
  '1964',
  '1965',
  '1966',
  '1967',
  '1968',
  '1969',
  '1970',
  '1971',
  '1972',
  '1973',
  '1974',
  '1975',
  '1976',
  '1977',
  '1978',
  '1979',
  '1980',
  '1981',
  '1982',
  '1983',
  '1984',
  '1985',
  '1986',
  '1987',
  '1988',
  '1989',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
];

const distributorTerritoriesFilter = {
  WORLDWIDE: {
    territoryName: 'WORLDWIDE',
    territoryParts: [
      {
        territoryName: 'NORTH AMERICA',
        territoryParts: ['United States', 'Canada', 'French Canada'],
      },
      {
        territoryName: 'CENTRAL AMERICA',
        territoryParts: ['Ecuador', 'Peru/Bolivia', 'Dominican Republic'],
      },
      {
        territoryName: 'EASTERN EUROPE',
        territoryParts: [
          'Bulgaria',
          'Czech Republic',
          'Hungary',
          'Poland',
          'Croatia',
          'Serbia',
          'CIS',
          'Romania',
        ],
      },
      {
        territoryName: 'EUROPE',
        territoryParts: [
          'Germany/Austria',
          'Switzerland/Germany',
          'France',
          'Italy',
          'Spain',
          'Benelux',
          'Scandinavia',
          'Iceland',
          'Portugal',
          'Greece',
        ],
      },
      {
        territoryName: 'FAR EAST',
        territoryParts: [
          'Japan',
          'Korea South',
          'Taiwan',
          'Hong Kong',
          'Singapore',
          'Brunei',
          'Malaysia',
          'Indonesia',
          'Philippines',
          'Thailand',
          'India',
          'Sri Lanka',
          'Pakistan',
          'China',
        ],
      },
      {
        territoryName: 'LATIN AMERICA',
        territoryParts: [
          'Argentina/Paraguay/Uruguay',
          'Brazil',
          'Mexico',
          'Chile',
          'Colombia',
          'Venezuela',
        ],
      },
      {
        territoryName: 'MIDDLE EAST',
        territoryParts: ['Lebanon', 'Saudi Arabia', 'Turkey', 'Israel'],
      },
      {
        territoryName: 'UNITED KINGDOM',
        territoryParts: [
          'Australia/New Zealand',
          'South Africa',
          'East Africa',
          'West Africa',
          'West Indies',
        ],
      },
    ],
  },
};

const defaultDatasetsForDatasetCreator = {
  totalFilms: 'Total Films',
  totalFilmsMeetingTargetThreshold: 'Total films meeting target threshold',
  totalFilmsInApprovedPredictionDataset:
    'Total films in Approved Prediction dataset',
};

const chartColors = [
  'rgba(0,123,255,1)', // blue - total films
  'rgba(102,16,242,1)', // purple - total films meeting target threshold
  // 'rgba(0,153,198,1)', turquoise - total films in approved prediction dataset
  'rgba(220,57,18,1)', // red
  'rgba(255,153,0,1)', // orange
  'rgba(16,150,24,1)', // green
  'rgba(153,0,153,1)', // purple
  'rgba(221,68,119,1)', // pink
  'rgba(102,170,0,1)', // dark green
  'rgba(184,46,46,1)', // deep red
  'rgba(49,99,149,1)', // dirty blue
  'rgba(153,68,153,1)', // dirty purple
  'rgba(34,170,153,1)', // dirty turquoise
  'rgba(170,170,17,1)', // dirty yellow
  'rgba(102,51,204,1)', // blue + purple
  'rgba(230,115,0,1)', // dirty orange
  'rgba(139,7,7,1)', // dirty red
  'rgba(50,146,98,1)', // dirty green
  'rgba(85,116,166,1)', // gray blue
  'rgba(59,62,172,1)', // deep blue
];

const filmDataQualityChartColors = [
  'rgba(0,123,255,1)',
  'rgba(102,16,242,1)',
  'rgba(232,62,140,1)',
  'rgba(220,53,69,1)',
  'rgba(253,126,20,1)',
  'rgba(255,193,7,1)',
  'rgba(40,167,69,1)',
  'rgba(32,201,151,1)',
  'rgba(23,162,184,1)',
  'rgba(52,58,64,1)',
];

const numberOfFilmsWithCriteriaOptions = [
  'budget',
  'revenue',
  'plot',
  'stars',
  'director',
  'distributor',
  'actors',
];

const numberOfFilmsWithAndOrOptions = ['AND', 'OR'];

const numberOfFilmsWithMaxMinOptions = ['max', 'min'];

const numberOfFilmsWithCharacteristicMeasureOptions = ['%', 'number', 'words'];

const numberOfFilmsWithPeriodMeasureOptions = ['months', 'days'];

const notificationTypes = ['alert', 'warning'];

const filmDataQualityCardId = 'data-quality';

const securityQuestions = [
  'Select an option...',
  'What is the first name of the person you first kissed?',
  'What is the last name of the teacher who gave you your first failing grade?',
  'What was the name of your elementary / primary school?',
  'In what city or town does your nearest sibling live?',
  'What time of the day were you born? (hh:mm)',
  'What is your pet’s name?',
  'In what year was your father born?',
  'Other...',
];

const customLibraryIds = ['-1', '-2'];

const predictionStatisticsDatasetLabels = [
  'Required data rating to be in prediction set',
  'Accuracy of initial prediction',
  'Average date of prediction before scheduled release',
  'Average number of prediction changes/swings per film',
];

const USAIsoCountryCode = 'US';

const privateHeaderIconLinks = {
  addFilm: 'addFilm',
  library: 'library',
  distributors: 'distributors',
  notifications: 'notifications',
  profile: 'profile',
};

const keyAlphabetic = {
  arrowRight: 'ArrowRight',
  arrowLeft: 'ArrowLeft',
  space: 'Space',
};

const distributorTerritoriesCountryCodes = {
  WW: 'WORLDWIDE',
  'DE-AT': 'Germany/Austria',
  'CH-DE': 'Switzerland/Germany',
  'PE-BO': 'Peru/Bolivia',
  'AR-PY-UY': 'Argentina/Paraguay/Uruguay',
  'CA-F': 'French Canada',
  'AU-NZ': 'Australia/New Zealand',
  CIS: 'CIS',
  SCD: 'Scandinavia',
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cape Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CD: 'Democratic Republic of the Congo',
  CG: 'Republic of the Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: `Cote d'Ivoire`,
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curacao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'South Korea',
  KR: 'North Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: `Lao People's Democratic Republic`,
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UM: 'United States Minor Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands (British)',
  VI: 'U.S. Virgin Islands',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

const minSearchPeopleInputLength = 2;

const filmsInputByMeFolderName = 'Films Input By Me';

const humanTypesEnum = {
  actor: 'actor',
  director: 'director',
  producer: 'producer',
  writer: 'writer',
  editor: 'editor',
  casting_director: 'casting_director',
  cinematographer: 'cinematographer',
  composer: 'composer',
  production_designer: 'production_designer',
  art_director: 'art_director',
  animator: 'animator',
};

const filmTemplateFileName = 'Template.xlsx';

const excelMimeType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const defaultSortingOrder = { sortField: '', sortType: '' };

const companyTypesEnum = {
  distributors: 'distributors',
  production_companies: 'production_companies',
};

const advancedSearchPageLastSearchLocalStorageKey =
  'advancedSearchPageLastSearchFieldValues';

const rememberAdvancedSearchPageLastSearchTimeout = 3 * 60 * 60 * 1000;

const filmDetailsPageCollapsibleSectionsDefault = {
  overview: {
    general: true,
    releaseInfo: true,
    shootingLocations: true,
    financials: true,
    miscellaneous: true,
  },
  similarFilms: {
    similarityControls: true,
    results: true,
  },
  cast: {
    directors: true,
    writers: true,
    producers: true,
    actors: true,
    editors: true,
    casting_directors: true,
    cinematographers: true,
    composers: true,
    production_designers: true,
    art_directors: true,
    animators: true,
  },
  companyCredits: {
    production_companies: true,
    special_effects: true,
    additional_companies: true,
  },
  awards: {
    awardsReceived: true,
    awardsNominated: true,
  },
  distributors: {
    northAmerica: true,
    uk: true,
    europe: true,
    farEast: true,
    latinAmerica: true,
    centralAmerica: true,
    middleEast: true,
    easternEurope: true,
  },
};

const filmDetailsPageCollapsibleSectionsLocalStorageKey =
  'filmDetailsPageCollapsibleSections';

const rememberFilmDetailsPageCollapsibleSectionsTimeout = 3 * 60 * 60 * 1000;

const authDataLocalStorageKey = 'authData';

// The server config value is 15 mins. The UI config value is reduced to prevent sending expired access token.
const accessTokenExpirationTimeout = 13 * 60 * 1000;

// The server config value is 30 mins. The UI config value is reduced to prevent sending expired refresh token.
const refreshTokenExpirationTimeout = 28 * 60 * 1000;

const filmPageCollapsibleSectionsDefault = {
  overview: true,
  similarFilms: true,
  predictions: true,
  financials: true,
  cast: true,
};

const filmPageCollapsibleSectionsLocalStorageKey =
  'filmPageCollapsibleSections';

const rememberFilmPageCollapsibleSectionsTimeout = 3 * 60 * 60 * 1000;

const analyticsReportPageCollapsibleSectionsDefault = {
  overview: true,
  categoryAnalysis: true,
};

const analyticsReportageCollapsibleSectionsLocalStorageKey =
  'analyticsReportPageCollapsibleSections';

const rememberAnalyticsReportPageCollapsibleSectionsTimeout =
  3 * 60 * 60 * 1000;

const personPageCollapsibleSectionsDefault = {
  actor: true,
  animator: true,
  'art director': true,
  'casting director': true,
  cinematographer: true,
  composer: true,
  director: true,
  editor: true,
  producer: true,
  'production designer': true,
  writer: true,
  star: true,
};

const personPageCollapsibleSectionsLocalStorageKey =
  'personPageCollapsibleSections';

const rememberPersonPageCollapsibleSectionsTimeout = 3 * 60 * 60 * 1000;

const dateRangeInYearFilters = {
  min: 1978,
  max: 2030,
};

const dataAuditChartTypes = {
  adjustableSet: {
    name: 'adjustable_set',
  },
  filmDataQualityTopPlot: {
    name: 'film_data_quality_top_plot',
  },
  filmDataQualityTopRating: {
    name: 'film_data_quality_top_rating',
    subtypes: {
      totalWithinThreshold: 'total_within_threshold',
      totalReleasedWithinThreshold: 'total_released_within_threshold',
      notReleasedWithinThreshold: 'not_released_within_threshold',
      notReleasedWithPredWithinThreshold:
        'not_released_with_pred_within_threshold',
    },
  },
};

const dataAuditPageCollapsibleSectionsLocalStorageKey =
  'dataAuditPageCollapsibleSections';

const rememberDataAuditPageCollapsibleSectionsTimeout = 3 * 60 * 60 * 1000;

const minWidthForFilmDataQualityBars = '5%';

const predictionSettingToDisplayEnum = {
  firstPrediction: 1,
  lastPrediction: 2,
  firstAndLastPrediction: 3,
};

const predictionsHeaderColumns = [
  {
    columnName: 'Ex.',
    generalSortingNotSupported: true,
    tooltip: 'Exclude from Calculations',
    headerClassName: '',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'PF',
    generalSortingNotSupported: true,
    tooltip: 'Performance',
    headerClassName: 'text-center',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'PROI',
    generalSortingNotSupported: false,
    tooltip: 'Predicted ROI',
    headerClassName: '',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'Film Title',
    generalSortingNotSupported: false,
    tooltip: null,
    headerClassName: '',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'Film ID',
    generalSortingNotSupported: false,
    tooltip: null,
    headerClassName: '',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'CO',
    generalSortingNotSupported: false,
    tooltip: 'Country of Origin',
    headerClassName: 'text-center',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'Rel. Date',
    generalSortingNotSupported: false,
    tooltip: 'Release Date',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'PT',
    generalSortingNotSupported: false,
    tooltip: 'Prediction Type',
    headerClassName: 'text-center',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'LP Date',
    generalSortingNotSupported: false,
    tooltip: 'Last Prediction Date',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.lastPrediction,
  },
  {
    columnName: 'LPI',
    generalSortingNotSupported: false,
    tooltip: 'Last Prediction Interval (number of days before release date)',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.lastPrediction,
  },
  {
    columnName: 'FP Date',
    generalSortingNotSupported: false,
    tooltip: 'First Prediction Date',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstPrediction,
  },
  {
    columnName: 'FPI',
    generalSortingNotSupported: false,
    tooltip: 'First Prediction Interval (number of days before release date)',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstPrediction,
  },
  {
    columnName: 'FYP Date',
    generalSortingNotSupported: false,
    tooltip: 'First YES Prediction Date',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'FYPI',
    generalSortingNotSupported: false,
    tooltip:
      'First YES Prediction Interval (number of days before release date)',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'Est Bd',
    generalSortingNotSupported: false,
    tooltip: 'Estimated Budget',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'Act Bd',
    generalSortingNotSupported: false,
    tooltip: 'Actual Budget',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'WWGS',
    generalSortingNotSupported: false,
    tooltip: 'Worldwide Gross',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
  {
    columnName: 'FPROI',
    generalSortingNotSupported: false,
    tooltip: 'First Prediction ROI',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstPrediction,
  },
  {
    columnName: 'LPROI',
    generalSortingNotSupported: false,
    tooltip: 'Last Prediction ROI',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.lastPrediction,
  },
  {
    columnName: 'AROI',
    generalSortingNotSupported: false,
    tooltip: 'Actual ROI',
    headerClassName: 'text-right',
    settingType: predictionSettingToDisplayEnum.firstAndLastPrediction,
  },
];

const predictionsPerformanceMap = {
  stable: 'Stable',
  no_to_yes: 'No then Yes',
  yes_to_no: 'Yes then No',
  trending_yes: 'Trending Up',
  trending_no: 'Trending Down',
};

const predictionsPerformanceState = {
  stable: 'Stable',
  noThenYes: 'No then Yes',
  yesThenNo: 'Yes then No',
  trendingUp: 'Trending Up',
  trendingDown: 'Trending Down',
};

const predictionsPredictionType = {
  custom: 'Custom Prediction',
  system: 'System Data Prediction',
};

const distributorFormats = [
  { label: 'All media', value: 'all media' },
  { label: 'Theatrical', value: 'theatrical' },
  { label: 'DVD', value: 'dvd' },
  { label: 'VHS', value: 'vhs' },
  { label: 'TV', value: 'tv' },
  { label: 'Video', value: 'video' },
  { label: 'Blu-ray', value: 'blu-ray' },
  { label: 'VOD', value: 'vod' },
];

const distributorFormatsMapping = {
  'all media': { label: 'All media', value: 'all media' },
  theatrical: { label: 'Theatrical', value: 'theatrical' },
  dvd: { label: 'DVD', value: 'dvd' },
  vhs: { label: 'VHS', value: 'vhs' },
  tv: { label: 'TV', value: 'tv' },
  video: { label: 'Video', value: 'video' },
  'blu-ray': { label: 'Blu-ray', value: 'blu-ray' },
  vod: { label: 'VOD', value: 'vod' },
};

export const FEATURE_FLAG = {
  dateAddedColumn: 'date_added_column',
  castUrls: 'cast_urls',
  moviePredictionsChart: 'movie_predictions_chart',
  notifications: 'notifications',
  reportRoiRange: 'report_roi_range',
};

const predictionTitle = {
  system: 'System Data Prediction',
  custom: 'Custom Prediction',
};

const predictionShortTitle = {
  system: 'SDP',
  custom: 'CP',
};

export default {
  aspectRatio,
  ratingOptions,
  genres,
  countries,
  territories,
  territoriesMapping,
  libraryHeaderColumns,
  searchResultsHeaderColumns,
  distributorsHeaderColumns,
  distributorFilmsHeaderColumns,
  awardsStatuses,
  producerRoles,
  actorTypes,
  editorRoles,
  moreLessOptions,
  inputTypeOptions,
  inputTypeOptionsEnum,
  yearsRange,
  distributorTerritoriesFilter,
  defaultDatasetsForDatasetCreator,
  chartColors,
  filmDataQualityChartColors,
  numberOfFilmsWithCriteriaOptions,
  numberOfFilmsWithAndOrOptions,
  numberOfFilmsWithMaxMinOptions,
  numberOfFilmsWithCharacteristicMeasureOptions,
  numberOfFilmsWithPeriodMeasureOptions,
  notificationTypes,
  filmDataQualityCardId,
  securityQuestions,
  customLibraryIds,
  predictionStatisticsDatasetLabels,
  USAIsoCountryCode,
  privateHeaderIconLinks,
  keyAlphabetic,
  distributorTerritoriesCountryCodes,
  minSearchPeopleInputLength,
  filmsInputByMeFolderName,
  humanTypesEnum,
  filmTemplateFileName,
  excelMimeType,
  defaultSortingOrder,
  companyTypesEnum,
  advancedSearchPageLastSearchLocalStorageKey,
  rememberAdvancedSearchPageLastSearchTimeout,
  filmDetailsPageCollapsibleSectionsDefault,
  filmDetailsPageCollapsibleSectionsLocalStorageKey,
  rememberFilmDetailsPageCollapsibleSectionsTimeout,
  personPageCollapsibleSectionsDefault,
  personPageCollapsibleSectionsLocalStorageKey,
  rememberPersonPageCollapsibleSectionsTimeout,
  authDataLocalStorageKey,
  accessTokenExpirationTimeout,
  refreshTokenExpirationTimeout,
  filmPageCollapsibleSectionsDefault,
  filmPageCollapsibleSectionsLocalStorageKey,
  rememberFilmPageCollapsibleSectionsTimeout,
  analyticsReportPageCollapsibleSectionsDefault,
  analyticsReportageCollapsibleSectionsLocalStorageKey,
  rememberAnalyticsReportPageCollapsibleSectionsTimeout,
  dateRangeInYearFilters,
  dataAuditChartTypes,
  dataAuditPageCollapsibleSectionsLocalStorageKey,
  rememberDataAuditPageCollapsibleSectionsTimeout,
  minWidthForFilmDataQualityBars,
  predictionsHeaderColumns,
  predictionsPerformanceMap,
  predictionsPerformanceState,
  predictionsPredictionType,
  predictionSettingToDisplayEnum,
  distributorFormats,
  distributorFormatsMapping,
  FEATURE_FLAG,
  predictionTitle,
  predictionShortTitle,
};
