import axios from 'axios';
import { isEmpty } from 'utils/helpers';
import { REACT_APP_API_URL } from 'config';
import constants from 'utils/constants';
import {
  getFromLocalStorage,
  addToLocalStorage,
  updateExpirationTime,
} from './localstorage';

export const API_TIMEOUT = 60000;
export const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: API_TIMEOUT,
  withCredentials: true,
});

function handleError(error) {
  const errorCode = error.response.status;

  if (errorCode === 401) {
    // eslint-disable-next-line no-alert
    alert(`You are not logged in, please log in to your account`);
    localStorage.clear();
    window.location.replace('/login');
  }
}

async function refreshAuthData(oldAuthData) {
  let authData = null;

  const requestConfig = {
    method: 'POST',
    headers: {},
    redirect: 'follow',
    url: '/refresh_jwt/',
    data: {
      refresh: oldAuthData.refreshToken,
    },
  };

  try {
    const res = await axiosInstance(requestConfig);

    if (res.status === 200) {
      getFromLocalStorage('username', constants.refreshTokenExpirationTimeout);

      authData = {
        ...oldAuthData,
        accessToken: res.data?.access,
        timeAdded: Date.now(),
      };

      updateExpirationTime(
        constants.authDataLocalStorageKey,
        authData,
        constants.refreshTokenExpirationTimeout,
      );
    }
  } catch (error) {
    // TODO:
  }

  return authData;
}

async function addAuthorizationHeader(headers) {
  const authData = getFromLocalStorage(
    constants.authDataLocalStorageKey,
    constants.refreshTokenExpirationTimeout,
  );

  if (authData) {
    const accessTokenExpirationTime =
      authData.timeAdded + constants.accessTokenExpirationTimeout;

    if (accessTokenExpirationTime > Date.now()) {
      headers.Authorization = `Bearer ${authData.accessToken}`;
      return;
    }

    const refreshTokenExpirationTime =
      authData.timeAdded + constants.refreshTokenExpirationTimeout;

    if (refreshTokenExpirationTime > Date.now()) {
      const refreshedAuthData = await refreshAuthData(authData);

      if (refreshedAuthData) {
        headers.Authorization = `Bearer ${refreshedAuthData.accessToken}`;
      }
    }
  }
}

async function sendRequest(method, url, data, config = {}) {
  let apiUrl = url;

  const headers = {
    ...(config.headers || {}),
  };

  await addAuthorizationHeader(headers);

  const requestConfig = {
    method,
    headers,
    redirect: 'follow',
  };

  if (config.onUploadProgress) {
    requestConfig.onUploadProgress = config.onUploadProgress;
  }

  if (config.responseType) {
    requestConfig.responseType = config.responseType;
  }

  if (config.cancelToken) {
    requestConfig.cancelToken = config.cancelToken;
  }

  if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data) {
    requestConfig.data = data;
  } else if (method === 'GET' && data) {
    const queryParams = Object.keys(data)
      .map((key) => !isEmpty(data[key]) && `${key}=${data[key]}`)
      .filter((value) => !!value)
      .join('&');

    if (queryParams) {
      apiUrl += (apiUrl.includes('?') ? '&' : '?') + queryParams;
    }
  }

  requestConfig.url = apiUrl;

  try {
    const response = await axiosInstance(requestConfig);
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

async function get(url, data, config) {
  return sendRequest('GET', url, data, config);
}
async function post(url, data, config) {
  return sendRequest('POST', url, data, config);
}
async function remove(url, data, config) {
  return sendRequest('DELETE', url, data, config);
}
async function put(url, data, config) {
  return sendRequest('PUT', url, data, config);
}
async function patch(url, data, config) {
  return sendRequest('PATCH', url, data, config);
}

export { get, post, remove, put, patch };
