export default {
  title: '',
  aspect_ratio: null,
  rating_mpaa: {
    intended: null,
    actual: null,
  },
  genres: [],
  release_date: [],
  summary: '',
  keywords: [],
  non_english_dialogues: '',
  cast: {
    directors: [],
    writers: [],
    producers: [],
    actors: [],
    editors: [],
    casting_directors: [],
    cinematographers: [],
    composers: [],
    production_designers: [],
    art_directors: [],
    animators: [],
  },
  awards: [],
  country: [],
  locations: [],
  // production: 'production',
  official_pages: [],
  ratings: {
    imdb: '0',
    metascore: '0',
    votes: '0',
  },
  runtime: null,
  financials: {
    production_budget: 0,
    marketing_budget: 0,
    profit: 0,
    gross: {
      worldwide: 0,
      us: 0,
      china: 0,
    },
    tax_incentives: '',
  },
  festivals: [],
  stems_from: [],
  theatrical_exhibition: [],
  based_on: [],
  inspired_by: null,
  current_stage: null,
  development_deal: '',
  development_agmt_url: null,
  production_companies: [],
  special_effects: [],
  additional_companies: [],
  distributors: {
    'North America': [],
    UK: [],
    Europe: [],
    'Far East': [],
    'Latin America': [],
    'Central America': [],
    'Middle East': [],
    'Eastern Europe': [],
  },
  downloadables: {
    film_data: [],
    script: [],
    development_deal: [],
    production_budget: [],
    marketing_budget: [],
    distribution_agreement: [],
  },
  is_private: false,
};
