import constants from 'utils/constants';
import CountryData from 'country-data';

export const getRegionTitleByCountry = (country) => {
  let regionTitle = null;

  if (!country) return regionTitle;

  const countryLowerCase = country.toLowerCase();

  regionTitle = Object.keys(constants.territories).find((territoryTitle) =>
    constants.territories[territoryTitle].find(
      (territoryCountry) => territoryCountry.toLowerCase() === countryLowerCase,
    ),
  );

  return regionTitle;
};

export const getCountryNameByCode = (countryCode) => {
  const countryName = constants.distributorTerritoriesCountryCodes[countryCode];

  if (countryName) return countryName;

  const countryInfo = CountryData.lookup.countries({
    alpha2: countryCode,
  })[0];

  if (countryInfo) return countryInfo.name;

  return null;
};

export const getCountryCodeByName = (countryName) => {
  const countryCode = Object.keys(
    constants.distributorTerritoriesCountryCodes,
  ).find(
    (key) => constants.distributorTerritoriesCountryCodes[key] === countryName,
  );

  if (countryCode) return countryCode;

  const countryInfo = CountryData.lookup.countries({
    name: countryName,
  })[0];

  if (countryInfo) return countryInfo.alpha2;

  return null;
};

export const getExistingCountryNamesByCodes = (countryCodes) => {
  const existingCountryNames = [];

  if (countryCodes?.length) {
    countryCodes.forEach((countryCode) => {
      const countryName = getCountryNameByCode(countryCode);

      if (countryName) existingCountryNames.push(countryName);
    });
  }

  return existingCountryNames;
};
