import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { PublicLayout } from 'components/public-layout/PublicLayout';

export const PublicRoute = ({ exact, path, component: Component, ...rest }) => (
  <Route
    {...rest}
    exact={exact}
    path={path}
    render={(routeProps) => (
      <PublicLayout>
        <Component {...routeProps} />
      </PublicLayout>
    )}
  />
);

PublicRoute.propTypes = {
  exact: PropTypes.any,
  path: PropTypes.any,
  component: PropTypes.any,
};
