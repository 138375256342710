import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { routes } from 'routes/routes';
import { isUserAuthenticated } from 'services/localstorage';
import { PrivateLayout } from 'components/private-layout/PrivateLayout';

export const PrivateRoute = ({
  exact,
  path,
  component: Component,
  displaySearchFieldComponent,
  displayFooterBasedOnPageContent,
  providePublicBrandInfoOnly = false,
  subtractFooterHeight = false,
}) => {
  if (isUserAuthenticated() || providePublicBrandInfoOnly) {
    return (
      <Route
        exact={exact}
        path={path}
        render={(routeProps) => (
          <PrivateLayout
            displaySearchFieldComponent={displaySearchFieldComponent}
            displayFooterBasedOnPageContent={displayFooterBasedOnPageContent}
            providePublicBrandInfoOnly={providePublicBrandInfoOnly}
            subtractFooterHeight={subtractFooterHeight}
          >
            <Component {...routeProps} />
          </PrivateLayout>
        )}
      />
    );
  }
  return <Redirect to={routes.LOGIN} />;
};

PrivateRoute.propTypes = {
  exact: PropTypes.any,
  path: PropTypes.any,
  component: PropTypes.any,
  displaySearchFieldComponent: PropTypes.any,
  displayFooterBasedOnPageContent: PropTypes.any,
  providePublicBrandInfoOnly: PropTypes.bool,
  subtractFooterHeight: PropTypes.bool,
};
