import React, { useState, useRef } from 'react';
import {
  Input,
  InputGroup,
  Label,
  Col,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import commonConstants from 'utils/constants';
import { advancedSearchDateFormat } from './constants';
import './DateRange.scss';
import 'react-datetime/css/react-datetime.css';

const DateRange = ({
  advancedSearchStartDate,
  setAdvancedSearchStartDate,
  advancedSearchStartDateInputValue,
  setAdvancedSearchStartDateInputValue,
  advancedSearchEndDate,
  setAdvancedSearchEndDate,
  advancedSearchEndDateInputValue,
  setAdvancedSearchEndDateInputValue,
  inputGroupClassName,
  labelText,
  colClassName,
  rowClassName = '',
  displayToWording = false,
}) => {
  const [
    advancedSearchEndDatePickerOpen,
    setAdvancedSearchEndDatePickerOpen,
  ] = useState(false);

  const [
    advancedSearchStartDatePickerOpen,
    setAdvancedSearchStartDatePickerOpen,
  ] = useState(false);

  const advancedSearchEndDateInput = useRef(null);

  const advancedSearchStartDateInput = useRef(null);

  const handleAdvancedSearchEndDateInputChange = (currentInputValue) => {
    if (moment(currentInputValue, advancedSearchDateFormat, true).isValid()) {
      setAdvancedSearchEndDate(currentInputValue);
    } else {
      setAdvancedSearchEndDate('');
    }

    setAdvancedSearchEndDateInputValue(currentInputValue);
  };

  const handleAdvancedSearchStartDateInputChange = (currentInputValue) => {
    if (moment(currentInputValue, advancedSearchDateFormat, true).isValid()) {
      setAdvancedSearchStartDate(currentInputValue);
    } else {
      setAdvancedSearchStartDate('');
    }

    setAdvancedSearchStartDateInputValue(currentInputValue);
  };

  const handleAdvancedSearchEndDatePickerChange = (currentMomentValue) => {
    const formattedDate = currentMomentValue.format(advancedSearchDateFormat);

    setAdvancedSearchEndDate(formattedDate);

    setAdvancedSearchEndDateInputValue(formattedDate);

    setAdvancedSearchEndDatePickerOpen(false);
  };

  const handleAdvancedSearchStartDatePickerChange = (currentMomentValue) => {
    const formattedDate = currentMomentValue.format(advancedSearchDateFormat);

    setAdvancedSearchStartDate(formattedDate);

    setAdvancedSearchStartDateInputValue(formattedDate);

    setAdvancedSearchStartDatePickerOpen(false);
  };

  const handleAdvancedSearchDatePickerOpenButtonClick = (
    advancedSearchDatePickerOpen,
    closeCalendar,
    openCalendar,
    advancedSearchDateInputRef,
    setAdvancedSearchDatePickerOpen,
  ) => {
    if (advancedSearchDatePickerOpen) {
      closeCalendar();
    } else {
      openCalendar();

      advancedSearchDateInputRef.current.focus();
    }

    setAdvancedSearchDatePickerOpen(!advancedSearchDatePickerOpen);
  };

  const renderAdvancedSearchStartDatePickerOpenButton = (
    props,
    openCalendar,
    closeCalendar,
  ) => (
    <button
      className="advancedSearchDatePickerOpenButton"
      type="button"
      onClick={() => {
        handleAdvancedSearchDatePickerOpenButtonClick(
          advancedSearchStartDatePickerOpen,
          closeCalendar,
          openCalendar,
          advancedSearchStartDateInput,
          setAdvancedSearchStartDatePickerOpen,
        );
      }}
    >
      <span className="icon-calendar"></span>
    </button>
  );

  const renderAdvancedSearchEndDatePickerOpenButton = (
    props,
    openCalendar,
    closeCalendar,
  ) => (
    <button
      className="advancedSearchDatePickerOpenButton"
      type="button"
      onClick={() => {
        handleAdvancedSearchDatePickerOpenButtonClick(
          advancedSearchEndDatePickerOpen,
          closeCalendar,
          openCalendar,
          advancedSearchEndDateInput,
          setAdvancedSearchEndDatePickerOpen,
        );
      }}
    >
      <span className="icon-calendar"></span>
    </button>
  );

  const dateTypes = {
    start: 'start',
    end: 'end',
  };

  const validateDate = (currentMoment, dateType) => {
    if (!currentMoment) return true;

    const isValidYear =
      currentMoment.year() >= commonConstants.dateRangeInYearFilters.min &&
      currentMoment.year() <= commonConstants.dateRangeInYearFilters.max;

    if (dateType === dateTypes.start && advancedSearchEndDate.length) {
      return isValidYear && currentMoment < moment(advancedSearchEndDate);
    }

    if (dateType === dateTypes.end && advancedSearchStartDate.length) {
      return isValidYear && currentMoment > moment(advancedSearchStartDate);
    }

    return isValidYear;
  };

  return (
    <div className="dateRangeContainer">
      <Label>{labelText}</Label>
      <Row className={rowClassName}>
        <div className={colClassName}>
          <InputGroup
            className={`advancedSearchDateContainer ${inputGroupClassName}`}
          >
            <Input
              autoComplete="off"
              placeholder="Start"
              type="text"
              value={advancedSearchStartDateInputValue}
              innerRef={advancedSearchStartDateInput}
              onChange={(event) =>
                handleAdvancedSearchStartDateInputChange(event.target.value)
              }
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="advancedSearchDatePickerContainer">
                <Datetime
                  value={advancedSearchStartDate}
                  timeFormat={false}
                  closeOnSelect
                  closeOnClickOutside
                  closeOnTab
                  dateFormat={advancedSearchDateFormat}
                  onClose={() => setAdvancedSearchStartDatePickerOpen(false)}
                  onChange={(currentMoment) =>
                    handleAdvancedSearchStartDatePickerChange(currentMoment)
                  }
                  renderInput={renderAdvancedSearchStartDatePickerOpenButton}
                  isValidDate={(currentMomentValue) =>
                    validateDate(currentMomentValue, dateTypes.start)
                  }
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
        {displayToWording && <span> to </span>}
        <div className={colClassName}>
          <InputGroup
            className={`advancedSearchDateContainer ${inputGroupClassName}`}
          >
            <Input
              autoComplete="off"
              placeholder="End"
              type="text"
              value={advancedSearchEndDateInputValue}
              innerRef={advancedSearchEndDateInput}
              onChange={(event) =>
                handleAdvancedSearchEndDateInputChange(event.target.value)
              }
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="advancedSearchDatePickerContainer">
                <Datetime
                  value={advancedSearchEndDate}
                  timeFormat={false}
                  closeOnSelect
                  closeOnClickOutside
                  closeOnTab
                  dateFormat={advancedSearchDateFormat}
                  onClose={() => setAdvancedSearchEndDatePickerOpen(false)}
                  onChange={(currentMoment) =>
                    handleAdvancedSearchEndDatePickerChange(currentMoment)
                  }
                  renderInput={renderAdvancedSearchEndDatePickerOpenButton}
                  isValidDate={(currentMomentValue) =>
                    validateDate(currentMomentValue, dateTypes.end)
                  }
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </Row>
    </div>
  );
};

DateRange.propTypes = {
  advancedSearchStartDate: PropTypes.string,
  setAdvancedSearchStartDate: PropTypes.func,
  advancedSearchStartDateInputValue: PropTypes.string,
  setAdvancedSearchStartDateInputValue: PropTypes.func,
  advancedSearchEndDate: PropTypes.string,
  setAdvancedSearchEndDate: PropTypes.func,
  advancedSearchEndDateInputValue: PropTypes.string,
  setAdvancedSearchEndDateInputValue: PropTypes.func,
  inputGroupClassName: PropTypes.string,
  labelText: PropTypes.string,
  colClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  displayToWording: PropTypes.bool,
};

export default DateRange;
