export const breakPointValues = {
  mediumLaptop: 1200,
  smallLaptop: 1100,
  medium: 991,
  ipad: 768,
  small: 576,
  tiny: 320,
};

const breakpoints = {
  mediumLaptop: `@media(max-width:  ${breakPointValues.mediumLaptop}px)`,
  smallLaptop: `@media(max-width: ${breakPointValues.smallLaptop}px)`,
  medium: `@media(max-width:  ${breakPointValues.medium}px)`,
  ipad: `@media(max-width:  ${breakPointValues.ipad}px)`,
  small: `@media(max-width:  ${breakPointValues.small}px)`,
  tiny: `@media(max-width:  ${breakPointValues.tiny}px)`,
};

export default breakpoints;
