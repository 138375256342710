export const isEmpty = (data) =>
  typeof data === 'undefined' || data === null || data === '';

export const getReleaseDatesWithCountry = (releaseDates) =>
  releaseDates?.filter(
    (item) => item.country || (item.country === '' && item.date === ''),
  );

export const toHoursAndMinutes = (totalMinutes) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${hours}h ${minutes ? `${minutes}m` : ''}`;
};

export const detectBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('chrome')) return 'chrome';
  if (userAgent.includes('firefox')) return 'firefox';
  if (userAgent.includes('safari')) return 'safari';

  return '';
};
