import { useContext } from 'react';
import { ViewportContext } from 'providers/Viewport';

const useViewport = () => {
  const viewPort = useContext(ViewportContext);

  return {
    ...viewPort,
  };
};

export default useViewport;
