import moment from 'moment';
import commonConstants from 'utils/constants';
import {
  SEARCH_IN_FOLDERS_TEXT,
  SEARCH_IN_FILMS_TEXT,
  SEARCH_IN_FOLDERS_API_TEXT,
  SEARCH_IN_FILMS_API_TEXT,
} from './constants';

export const getTextAfterSearchInLibrarySectionClick = (
  searchInLibrarySectionText,
  currentSearchFilmsInputValue,
) => {
  let currentSearchFilmsInputValueWithoutPrefix = currentSearchFilmsInputValue;

  if (
    currentSearchFilmsInputValue.includes(SEARCH_IN_FOLDERS_TEXT) ||
    currentSearchFilmsInputValue.includes(SEARCH_IN_FILMS_TEXT)
  ) {
    const replacePattern = `(${SEARCH_IN_FOLDERS_TEXT} )|(${SEARCH_IN_FILMS_TEXT} )`;
    const regExp = new RegExp(replacePattern, 'g');

    currentSearchFilmsInputValueWithoutPrefix = currentSearchFilmsInputValue.replace(
      regExp,
      '',
    );
  }

  return `${searchInLibrarySectionText} ${currentSearchFilmsInputValueWithoutPrefix}`;
};

export const getLibrarySearchType = (currentSearchFilmsInputValue) => {
  if (currentSearchFilmsInputValue.includes(SEARCH_IN_FOLDERS_TEXT))
    return SEARCH_IN_FOLDERS_API_TEXT;

  if (currentSearchFilmsInputValue.includes(SEARCH_IN_FILMS_TEXT))
    return SEARCH_IN_FILMS_API_TEXT;

  return undefined;
};

export const getLibrarySearchValue = (currentSearchFilmsInputValue) => {
  const replacePattern = `(${SEARCH_IN_FOLDERS_TEXT} )|(${SEARCH_IN_FILMS_TEXT} )`;
  const regExp = new RegExp(replacePattern, 'g');

  return currentSearchFilmsInputValue.replace(regExp, '');
};

export const getNullableNumberFromString = (value) => {
  const parsed = parseFloat(value);

  if (Number.isNaN(parsed)) return null;

  return parsed;
};

export const getNumberInUSALocaleOrDefaultString = (value) => {
  let formattedValue = value;

  if (value && value.length && value.slice(-1) !== '.') {
    const valueNumber = getNullableNumberFromString(value.replace(/,/g, ''));

    if (valueNumber) {
      formattedValue = new Intl.NumberFormat('en-US').format(valueNumber);
    }
  }

  return formattedValue;
};

export const getNullableDateFromString = (value, dateFormat) => {
  if (moment(value, dateFormat, true).isValid()) return new Date(value);

  return null;
};

export const getEncodedUriComponentFromObject = (object) =>
  encodeURIComponent(JSON.stringify(object));

export const getObjectFromEncodedUriComponent = (uriComponent) =>
  JSON.parse(decodeURIComponent(uriComponent));

export const getMoreLessApiOptionValue = (option) => {
  if (option === commonConstants.moreLessOptions[0]) return 'mt';

  if (option === commonConstants.moreLessOptions[1]) return 'lt';

  return 'mt';
};

export const getAddRemoveInputsValue = (addRemoveInputs) =>
  addRemoveInputs
    .filter((addRemoveInput) => addRemoveInput.inputValue.length)
    .map((addRemoveInput) => addRemoveInput.inputValue);

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const getAdditionalCriteriaInputsValue = (additionalCriterias) => {
  const groupedCriterias = groupBy(
    additionalCriterias,
    'selectedInputTypeOption',
  );

  return commonConstants.inputTypeOptions.map((inputOption) => ({
    type: commonConstants.inputTypeOptionsEnum[inputOption],
    value: groupedCriterias[inputOption]
      ? groupedCriterias[inputOption]
          .filter((criteria) => criteria.inputValue.length)
          .map((criteria) => criteria.inputValue)
      : [],
  }));
};

export const removeBlankAttributes = (obj) =>
  Object.entries(obj)
    .map(([k, v]) => [
      k,
      v && typeof v === 'object' ? removeBlankAttributes(v) : v,
    ])
    // eslint-disable-next-line no-return-assign
    .reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export const convertNumberIntoAbbreviatedString = (value) =>
  Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: 0,
  }).format(value);

export const getSelectedSuggestionsIds = (suggestions) =>
  suggestions?.map((suggestion) => suggestion.id) ?? [];
