import React from 'react';
import {
  Input,
  InputGroup,
  Label,
  Col,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

const Budget = ({
  inputGroupClassName,
  handleBudgetChange,
  labelValue,
  budgetValue,
  colClassName,
  rowClassName = '',
  hideLabel = false,
  displayToWording = false,
}) => (
  <div className="budgetContainer">
    {!hideLabel && <Label>{labelValue}</Label>}
    <Row className={rowClassName}>
      <div className={colClassName}>
        <InputGroup className={inputGroupClassName}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>$</InputGroupText>
          </InputGroupAddon>
          <Input
            autoComplete="off"
            placeholder="min"
            onChange={(event) => handleBudgetChange(event.target.value, 'min')}
            value={budgetValue.min}
          />
        </InputGroup>
      </div>
      {displayToWording && <span> to </span>}
      <div className={colClassName}>
        <InputGroup className={inputGroupClassName}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>$</InputGroupText>
          </InputGroupAddon>
          <Input
            autoComplete="off"
            placeholder="max"
            onChange={(event) => handleBudgetChange(event.target.value, 'max')}
            value={budgetValue.max}
          />
        </InputGroup>
      </div>
    </Row>
  </div>
);

Budget.propTypes = {
  inputGroupClassName: PropTypes.string,
  handleBudgetChange: PropTypes.func,
  labelValue: PropTypes.string,
  budgetValue: PropTypes.object,
  colClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  hideLabel: PropTypes.bool,
  displayToWording: PropTypes.bool,
};

export default Budget;
